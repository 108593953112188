import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import "./splash.css";
import Web3 from "web3";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { setRacerLogin } from "../../../redux/Account/actions";
import {loadAccount, syncAccountWallet, axiosInstance} from "../../../utils"
import {LoginBox, LoginInline} from "../../Organisms"
import { NoToneMapping } from "three";
import {InjectedConnector, } from "@web3-react/injected-connector";

let web3;

const HARDHAT_CHAIN_ID = 31337;
const MOONBASE_CHAIN_ID = 1287;
const MOONBEAM_CHAIN_ID = 1284;

const supportedChainIds = [
  HARDHAT_CHAIN_ID,
  MOONBASE_CHAIN_ID,
];

const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});

export const Splash = () => {
    const dispatch = useDispatch();
    const { page_loaded }= useSelector((state) => state.app);
    const { racerID, accountAddress } = useSelector((state) => state.account);

    const { chainId, account } = useWeb3React();

    if((racerID.length > 1)&&(accountAddress.length > 1)) {
        // dispatch(updateLocation('workshop'));
    }

    const [pageSet, setPage] = useState(false);

    let bg_image_side = 'https://media.exiledracers.com/assets/banners/Banner_Serf_Cinematic1.jpeg';
    let bg_image = 'https://media.exiledracers.com/assets/game/builder/Splash_Background_-_Serf_Centered_1_1665676555319.jpeg';
    let logo_image = 'https://media.exiledracers.com/global/exr_logo_white_stacked.png';
    
    //let register_button_color_class = 'round-border-button-whitegreen';
    //let register_button_color_class = 'round-border-button-whitepink';
    let register_button_color_class = 'round-border-button-whitelightlink';
    //let login_button_color_class = 'white-green-link';
    //let login_button_color_class = 'white-pink-link';
    let login_button_color_class = 'grey-lightlink-link';
    let login_button_color_class2 = 'grey-lightlink-link';

    document.addEventListener('DOMContentLoaded', function() {
        
        window.resetCenterHeight();
        window.startPageModals();
        window.resetBodyWidth();
        startSwappingHoverButtons();

        window.$("#exr-logo-button").click(function() {
            window.location.reload();
        });

        // window.$("#discord-access-button").click(function() {
        //     window.open('https://discord.gg/exiledracers');
        // });

        // window.$("#twitter-access-button").click(function() {
        //     window.open('https://twitter.com/exiledracers');
        // });

        // window.$("#medium-access-button").click(function() {
        //     window.open('https://blog.exiledracers.com/exiled-racers-announces-stunning-3d-nft-project-with-bonus-play-and-earn-racing-manager-backed-by-5a2fecac5419');
        // });

        setTimeout(function(){
            window.startPageModals();
        },1000);
    });

    function startSwappingHoverButtons() {
        window.$("#discord-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Discord_White_Gif0.png');
        });

        window.$("#twitter-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png');
        });

        window.$("#medium-access-button").mouseover(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        }).mouseout(function() {
            window.$(this).attr("src",'https://media.exiledracers.com/global/splash/Medium_White_Gif0.png');
        });
    }

    function requestTrainingAccess() {
        startSwappingHoverButtons();
    }

    function showLoginBox(e) {
        e.preventDefault();
        dispatch(updateLocation('signin'));
        window.location.href="/#/signin&p=login";
    }

    function showRegisterBox() {
        dispatch(updateLocation('signin'));
        // window.location.href="/#/register";
        // window.location.href="https://trackmeet.exiledracers.com";
        // window.location.href="https://pink.exiledracers.com";
        // window.location.href="https://base.exiledracers.com";
        window.location.href="https://ghost.exiledracers.com";
    }

    function showLoginBoxModal() {
        window.startPageModals();
        var lbox = document.getElementById('login-box');
        var lbox_instance = window.M.Modal.getInstance(lbox);
        lbox_instance.open();
    }

    function goToLearnSite() {
        dispatch(updateLocation("gameplay"));
        window.location.href="/#/gameplay";
    }

    function goToMintSite() {
        window.location.href="https://mint.exiledracers.com"
    }

    function goToCollectionViewer() {
        window.location.href="https://view.exiledracers.com"
    }

    function showRacewayPage() {
        dispatch(updateLocation("raceway"));
        window.location.href="/#/raceway";
    }

    window.addEventListener('resize', function() {
        window.resetCenterHeight();
    });

    if(setPage === false) {
        pageSet(true);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="main-landing-page">
            <div id="splash-image-container" style={{width:'100%',height:'100vh',overflow:'hidden',position:'fixed',top:'0px',left:'0px', zIndex:'-20',background:'url('+bg_image+')',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
            <div className="exr-trans-black-bg center-align white-text" style={{display:'block',width:'100%',position:'absolute',zIndex:'20',top:'0px',left:'0px',padding:'20px',fontSize:'20px'}}>
            <a className="lightlink-cyan-link" target="_blank" href="https://ghost.exiledracers.com/">
                    EXR GHOST RIDERS:
                    </a>&nbsp;&nbsp; RACING OPEN -&nbsp;
                    <a className="lightlink-cyan-link" target="_blank" href="https://ghost.exiledracers.com/">START RACING</a>
                    {/* <a className="pink-pink-link" target="_blank" href="https://pink.exiledracers.com/">
                    PINK RACING LEAGUE:
                    </a>&nbsp;&nbsp; WEEKLY RACING COMPLETE -&nbsp;
                    <a className="pink-pink-link" target="_blank" href="https://pink.exiledracers.com/">VIEW FINAL RESULTS</a> */}
                    {/* <span className="exr-light-green-text">EXR TRACK MEET:</span>&nbsp;&nbsp;RESULTS & STREAM AVAILABLE -&nbsp;
                    <a className="green-green-link" title="STREAM AVAILABLE " target="_blank" href="https://trackmeet.exiledracers.com/#/results">VIEW NOW</a> */}
                    {/* <span className="exr-light-green-text">LUNAR RACING FESTIVAL:</span>&nbsp;&nbsp;FINALS RACES READY -&nbsp;&nbsp;
                    <span className="white-red-link" title="Register For Exiled Racers" onClick={()=>showRacewayPage()}>WATCH NOW</span> */}



            </div>
            <div className="col s12 transparent" style={{marginTop:'12vh',position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="row center-align">
                        <div className="col s12 m10 offset-m1 l4 offset-l4">
                        <a href="https://www.exiledracers.com/" className="white-text">
                        <img id="exr-logo-button" style={{cursor:'pointer',marginLeft:'0px',marginTop:'3vh',minWidth:'250px'}} title="Exiled Racers" width="65%" src={logo_image} />
                        </a>
                        </div>
                    </div>
                    <div className="row center-align" style={{marginTop:'19vh'}}>
                        <div className="col s12 center-align show-on-large hide-on-med-and-down">
                            <span style={{display:'table',margin:'0 auto'}}>
                                <span className={"round-border-button "+register_button_color_class} data-target="login-box" style={{width:'auto',margin:'0 auto',fontSize:'40px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Register For Exiled Racers" onClick={()=>showRegisterBox()}>
                                    REGISTER NOW
                                </span>
                            </span>
                        </div>
                        <div className="col s12 show-on-med-and-down hide-on-large-only center-align">
                            <span className={"round-border-button "+register_button_color_class} data-target="login-box" style={{fontSize:'28px',height:'42px'}} title="Register For Exiled Racers" onClick={()=>showRegisterBox()}>
                                REGISTER NOW
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s10 offset-s1 l6 offset-l3 center-align white-text" style={{padding:'0px'}}>
                            
                            <a href="https://www.exiledracers.com/#/gameplay" className="white-text" onClick={()=>goToLearnSite()}>
                                <span className={"headline-text "+login_button_color_class2+" center-align"} title="Learn About EXR" style={{marginBottom:'10px'}}>
                                    LEARN
                                </span>
                            </a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <a href="https://view.exiledracers.com/" className="white-text" target="_blank">
                                <span className={"headline-text "+login_button_color_class2+" center-align"} title="View NFT Collection" style={{marginBottom:'10px'}} onClick={() => goToCollectionViewer() }>
                                    COLLECT
                                </span>
                            </a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <a href="https://mint.exiledracers.com/" className="white-text" target="_blank">
                                <span className={"headline-text "+login_button_color_class2+" center-align"} title="Go To Mint Site" style={{marginBottom:'10px'}} onClick={() => goToMintSite() }>
                                    MINT
                                </span>
                            </a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <a href="https://www.exiledracers.com/#/signin" className="white-text" onClick={(e)=>showLoginBox(e)}>
                                <span className={"headline-text "+login_button_color_class+" center-align"} title="Login To EXR" style={{marginBottom:'10px'}}>
                                    LOGIN
                                </span>
                            </a>
                        </div>
                    </div>
                    <div id="social-button-row" className="row center-align" style={{position:'relative',width:'100%',marginTop:'13vh',zIndex:'100'}}>
                        <div className="col s10 offset-s1 l4 offset-l4">
                            <a href="https://discord.gg/exiledracers" className="white-text" target="_blank">
                            <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'60px'}} width="10%" title="EXR Discord"
                                id="discord-access-button" src="https://media.exiledracers.com/global/splash/Discord_White_Gif0.png"/>
                            </a>
                            <a href="https://twitter.com/exiledracers" className="white-text" target="_blank">
                            <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'60px'}} width="10%" title="EXR Twitter"
                                id="twitter-access-button" src="https://media.exiledracers.com/global/splash/Twitter_White_Gif0.png"/>
                            </a>
                            <a href="https://blog.exiledracers.com/" className="white-text" target="_blank">
                            <img style={{cursor:'pointer',marginLeft:'20px',minWidth:'60px'}} width="10%" title="EXR Blog"
                                id="medium-access-button" src="https://media.exiledracers.com/global/splash/Medium_White_Gif0.png"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoginBox />
    </div>);
};
